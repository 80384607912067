import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpStatusCode } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { iif, Observable, of, throwError } from 'rxjs';
import { catchError, concatMap, delay, retryWhen } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AuthService } from '../services/auth.service';
import { StartupService } from '../services/startup.service';
import { requestUrlPhoto } from '../services/users-profile-pics.service';

export interface RetryRequestOptions {
  maximumRetries: number;
  retryDelay: number;
  skipRetry: boolean;
}

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  private retryRequestOptions: RetryRequestOptions = {
    maximumRetries: environment.maximumRetries,
    retryDelay: environment.retryDelay,
    skipRetry: false
  };
  constructor(
    public auth: AuthService,
    public _startupService: StartupService,
    protected _router: Router,
    protected activatedRoute: ActivatedRoute) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let copyReq;

    this.retryRequestOptions.maximumRetries = environment.maximumRetries ?? 3;
    this.retryRequestOptions.retryDelay = environment.retryDelay ?? 300;
    this.retryRequestOptions.skipRetry = request.headers.has('skipRetry');

    if (!request.url.includes('startup-service') && !request.url.includes('accessed-page-log') && !request.url.includes('viacep')) {
      //reseta hasCorsErrorOnRequestDifferentThanStartup uma vez q eu guardo essa informação somente da ultima requisição
      this._startupService.hasCorsErrorOnRequestDifferentThanStartup = false;
    }

    if (request.url.includes('AWSAccessKeyId') || request.url.includes(window.location.origin)) {
      // if is s3 url
      copyReq = request.clone({})
      return next.handle(copyReq).pipe(catchError(x => this.handleAuthError(x)));
    }
    copyReq = request.clone({
      setHeaders: {
        Authorization: `Bearer ${this.auth.getCurrentToken()}`
      }
    });
    return next.handle(copyReq).pipe(this.retryPipe(), catchError(x => this.handleAuthError(x)));
  }

  private handleAuthError(err: HttpErrorResponse): Observable<any> {
    // tratamento para o request de photos de usuário "api/user/url-photo" nesse caso caso aconteça um forbiden, só não vou mostrar a imagem
    if (!err.url.includes(requestUrlPhoto)) {

      if (err.status === HttpStatusCode.Unauthorized) {
        this.auth.logoutAndRedirectToLogin();

        return of(err.message);
      }

      if (err.status === HttpStatusCode.Forbidden) {
        this._router.navigate(['/unauthorized']);
        return of(err.message);
      }
    }

    if (this._startupService.isOnline() && err.status === 0 && !err.url.includes(window.location.origin) && !err.url.includes('startup-service') && !err.url.includes('accessed-page-log') && !err.url.includes('viacep')) {

      //caso tenha acontecido um erro de cors que não foi do startup service eu guardo essa informação na variavel hasCorsErrorOnRequestDifferentThanStartup
      this._startupService.hasCorsErrorOnRequestDifferentThanStartup = true;

      if (!(window.location.href.includes('maintenance') || window.location.href.includes('offline') )) {
        localStorage.setItem('lastRouteBeforeError', window.location.pathname)
        this._router.navigate(['/maintenance']);
      }
    }
    else if (!this._startupService.isOnline()) {
      if (!window.location.href.includes('offline')) {
        localStorage.setItem('lastRouteBeforeError', window.location.pathname)
        this._router.navigate(['/offline']);
      }

    }

    throw err;
  }

  retryPipe<T>() {
    return retryWhen<T>((errors: Observable<HttpErrorResponse>) =>
      errors.pipe(
        concatMap((error: HttpErrorResponse, retryId: number) =>
          iif(() => { return this.retryRequestOptions.skipRetry || retryId >= this.retryRequestOptions.maximumRetries || (error.status < 500 && error.status !== 0) }, this.returnError(error), this.retryError(error, retryId, this.retryRequestOptions.retryDelay))
        ),
      ),
    );
  }

  returnError(error: HttpErrorResponse) {
    return throwError(error);
  }

  retryError(error: HttpErrorResponse, retryId: number, retryDelay: number) {
    return of(error).pipe(delay(retryDelay * retryId));
  }

}
